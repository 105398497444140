import supercluster from 'points-cluster'

const defaultMinMaxOptions = {
  latPad: 0,
  lngPad: 0
}

export function getMinMax(points, options) {
  const {latPad, lngPad} = {...defaultMinMaxOptions, ...options}

  let maxLat = false
  let minLat = false
  let maxLng = false
  let minLng = false

  // Notes:
  // - The latitude reaches values from -90° at the south pole over 0° at
  //   the equator to +90° at the north pole.
  // - The longitude is an angle measured from the meridian of origin (0°)
  //   to 180° in an easterly direction and 180° in a westerly direction.

  points.forEach((point) => {
    if (maxLat === false || point.lat > maxLat) {
      maxLat = point.lat
    }
    if (minLat === false || point.lat < minLat) {
      minLat = point.lat
    }
    if (maxLng === false || point.lng > maxLng) {
      maxLng = point.lng
    }
    if (minLng === false || point.lng < minLng) {
      minLng = point.lng
    }
  })

  return {
    sw: {
      lat: minLat - latPad,
      lng: minLng - lngPad
    },
    ne: {
      lat: maxLat + latPad,
      lng: maxLng + lngPad
    }
  }
}

const CLUSTER = {
  defaultMinZoom: 0,
  defaultMaxZoom: 13,
  defaultRadius: 50
}

export function createClusters(trucks, mapLayout) {
  if (!mapLayout.bounds) {
    return []
  }

  const flatTrucks = trucks.map((truck) => ({
    lat: truck.lastPosition.lat,
    lng: truck.lastPosition.lng,
    id: truck.id
  }))

  // FIXME: Replace with modern `supercluster` library
  // https://github.com/mapbox/supercluster
  const clusters = supercluster(flatTrucks, {
    minZoom: CLUSTER.defaultMinZoom,
    maxZoom: CLUSTER.defaultMaxZoom,
    radius: CLUSTER.defaultRadius
  })

  const truckClusters = clusters(mapLayout)

  const trucksById = {}
  trucks.forEach((truck) => {
    trucksById[truck.id] = truck
  })

  const clusterResult = truckClusters.map(({wx, wy, numPoints, points}) => ({
    lat: wy,
    lng: wx,
    numPoints,
    points,
    trucks: points.map(({id}) => trucksById[id])
  }))

  return clusterResult
}

export function addMotionLine(googleMapsAPI, positions) {
  if (!googleMapsAPI) {
    return
  }

  const {map, maps} = googleMapsAPI
  const path = positions.map(({lat, lng}) => new maps.LatLng(lat, lng))

  const polyline = new maps.Polyline({
    map,
    zIndex: 1001,
    geodesic: true,
    strokeColor: '#0050ef',
    strokeOpacity: 0.5,
    strokeWeight: 4,
    path
  })

  return polyline
}
