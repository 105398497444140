import i18n, {InitOptions} from 'i18next'
import detector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

import {backendResolver} from './backend'

const detectorOptions = {
  order: ['querystring', 'navigator', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain']
}

const loader = async (filename: string) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'You need to setup your Localization-Loader! Please have a look at the @hconnect/uikit readme-file on how to setup Translations.'
    )
    console.info(`Load Translation for uikit [${filename}]`)
  }
  try {
    return await import(`./${filename}.json`)
  } catch (err: unknown) {
    if (process.env.NODE_ENV === 'development') {
      console.error(`Could not load Translation for uikit [${filename}]`)
    }
    throw err
  }
}

export const Localization = (props: InitOptions = {}) => {
  const params: InitOptions = {
    returnNull: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    compatibilityJSON: 'v3',
    detection: detectorOptions,
    saveMissing: process.env.NODE_ENV === 'development',
    backend: {
      loader
    },
    ...props
  }
  return i18n.use(backendResolver).use(detector).use(initReactI18next).init(params)
}

export {backendResolver}

// no longer needed, apps can import useTranslation from react-i18next directly
export {useTranslation} from 'react-i18next'
